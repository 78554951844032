import gql from 'graphql-tag'

export const UPDATE_USER_MFA_ENABLED = gql`
    mutation updateUserMfaEnabled($userId: uuid = "", $mfaEnabled: Boolean = false) {
        update_ted2_users_by_pk(pk_columns: {user_id: $userId}, _set: {mfa_enabled: $mfaEnabled}) {
            auth0_id
            mfa_enabled
            status
        }
    }
`

export const ENABLE_MFA = gql`
    mutation enable2Fa($userId: uuid = "") {
        enable_2fa(user_id: $userId) {
            data
            error
            status
        }
    }
`

export const DISABLE_MFA = gql`
    mutation disable2Fa($userId: uuid = "") {
        disable_2fa(user_id: $userId) {
            data
            message
            status
        }
    }
`
