import {API_KEY_SECRET, REST_API} from '@/utils/constants'
import axios from 'axios'
import {getInstance} from '@/auth'

class StaffService {
    constructor() {
        // NOTE: In the new api, it'll be /api/v1
        this.url = `${REST_API}/v1/api/users`
    }

    async enable2Fa(userId) {
        const token = await getInstance().getTokenSilently()
        const url = `${this.url}/${userId}/enable-2fa`
        const {data} = axios.post(
            url,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-API-KEY': API_KEY_SECRET
                }
            }
        )
        return data
    }

    async disable2Fa(userId) {
        const token = await getInstance().getTokenSilently()
        const url = `${this.url}/${userId}/disable-2fa`
        const {data} = axios.post(
            url,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-API-KEY': API_KEY_SECRET
                }
            }
        )
        return data
    }
}

export const staffService = new StaffService()
