<template>
    <div class="bg-white-pure list-wrapper">
        <div class="flex pb-8 items-center w-full justify-between">
            <div class="flex items-center">
                <IconSvg class="text-primary-light w-10 mr-2" />
                <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="adminTitle">
                    Staff Management
                </h1>
            </div>
            <v-btn
                depressed
                color="success"
                class="mr-2 v-btn--flat inline"
                @click="handleInviteNewStaff"
            >
                <fa-icon icon="envelope" class="mr-1" />
                Invite new staff
            </v-btn>
        </div>
        <div class="flex items-center mb-6 search">
            <v-text-field
                solo
                flat
                hide-details
                outlined
                single-line
                dense
                label="Search"
                prepend-inner-icon="search"
                v-model="filter.search"
                @input="filterChangeHandler"
            ></v-text-field>
        </div>

        <div class="flex items-center mb-6">
            <fa-icon icon="filter" class="tablet-hide text-sm text-black mr-4" />
            <div class="w-150 mr-2">
                <v-autocomplete
                    dense
                    single-line
                    hide-details
                    outlined
                    clearable
                    label="Status"
                    v-model="filter.status"
                    :items="userStatuses"
                    @change="filterChangeHandler"
                ></v-autocomplete>
            </div>
            <div class="w-150 mr-2">
                <v-select
                    dense
                    single-line
                    hide-details
                    outlined
                    clearable
                    label="Role"
                    v-model="filter.role"
                    :items="userRoles"
                    @change="filterChangeHandler"
                ></v-select>
            </div>
            <div class="mr-2">
                <LocationProgramFilter
                    dense
                    single-line
                    hide-details
                    outlined
                    clearable
                    :user-scoped="false"
                    v-model="filter.locationProgram"
                    @change="filterChangeHandler"
                />
            </div>
            <v-btn depressed class="mr-4" data-cy="resetFiltersHandler" @click="resetFiltersHandler"
                >Reset</v-btn
            >
        </div>

        <div class="a-user-list">
            <div class="flex flex-row">
                <div class="flex items-center w-full">
                    <UserTable
                        ref="userTable"
                        :table-data="users"
                        :offset="pagination.offset"
                        :limit="pagination.limit"
                        :sort="sort"
                        :total="total"
                        :loading="loading"
                        @update-pagination="updatePagination"
                        @perform-action="performAction"
                        @reload-users="reloadUsersHandler"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GET_USERS from '@/graphql/queries/getUsersForAdmin.gql'
import DEACTIVATE_USER from '@/graphql/mutations/deactivateUser.gql'
import REACTIVATE_USER from '@/graphql/mutations/reactivateUser.gql'
import REINVIET_USER from '@/graphql/mutations/reinviteUser.gql'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import {mapState} from 'vuex'
import UserTable from '@/components/user-management/partials/UserTable'
import IconSvg from '@/assets/icons/nav/icons_cog.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import {UserHelper, UserAction} from '@/components/shared/mixins/userMixin'
import {StuniHelper, StuniAction} from '@/components/shared/mixins/stuniMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import RE_INVITE_STAFF from '@/graphql/mutations/reinviteUser.gql'

const DEFAULT_PAGINATION = {
    offset: 0,
    limit: 10
}
const DEFAULT_FILTER = {
    search: null,
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    status: null,
    role: null
}
const DEFAULT_SORT = ['firstName']

export default {
    name: 'UserList',
    components: {UserTable, IconSvg, LocationProgramFilter},
    mixins: [UserHelper, MessageDialog, StuniHelper],
    data() {
        return {
            loading: false,
            users: [],
            total: 0,
            pagination: {...DEFAULT_PAGINATION},
            sort: [...DEFAULT_SORT],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: {...DEFAULT_FILTER.locationProgram}
            }
        }
    },
    apollo: {
        users: {
            debounce: 200,
            query: GET_USERS,
            variables() {
                return {
                    ...this.pagination,
                    filter: this.queryFilter,
                    orderBy: snakecaseKeys(this.orderBy, {deep: true})
                }
            },
            result({data: {users, total}}) {
                this.total = total.aggregate.count
                users = camelcaseKeys(users || [], {deep: true})
                users.forEach(user => {
                    if (!user.locationPrograms.length) return

                    user.locationPrograms = user.locationPrograms.map(
                        ({locationProgram}) => locationProgram
                    )
                    user.locations = user.locationPrograms.reduce((locations, {location}) => {
                        if (!locations.find(loc => loc.locationId === location.locationId))
                            locations.push(location)
                        return locations
                    }, [])
                })

                this.users = users
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    beforeMount() {
        this.loadFilter(this.$route.query)
    },
    mounted() {
        this.$root.$on('change-user-status', this.changeUserStatus)
        this.$root.$on('staff-invited', this.staffInviteComplete)
        this.$root.$on('reinvite-user', this.reInviteUserAction)
    },
    methods: {
        reloadUsersHandler() {
            this.$apollo.queries.users.refetch()
        },
        staffInviteComplete(userId) {
            this.closeModal()
            this.refetchUsers()
            this.$router.push({name: 'user-details', params: {userId}})
        },
        handleInviteNewStaff() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', null)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalInviteUser')
        },
        loadFilter(query) {
            this.filter.search = query.search || DEFAULT_FILTER.search
            this.filter.locationProgram = {
                locationId:
                    (!isNaN(query.locationId) && parseInt(query.locationId)) ||
                    DEFAULT_FILTER.locationProgram.locationId,
                locationProgramId:
                    (!isNaN(query.locationProgramId) && parseInt(query.locationProgramId)) ||
                    DEFAULT_FILTER.locationProgram.locationProgramId
            }
            this.filter.status = query.status || DEFAULT_FILTER.status
            this.filter.role = query.role || DEFAULT_FILTER.role

            this.pagination.limit =
                (!isNaN(query.limit) && parseInt(query.limit)) || DEFAULT_PAGINATION.limit
            this.pagination.offset =
                (!isNaN(query.offset) && parseInt(query.offset)) || DEFAULT_PAGINATION.offset

            this.sort = (query.sort && (Array.isArray(query.sort) ? query.sort : [query.sort])) || [...DEFAULT_SORT]
        },
        updateRoute() {
            const query = {
                ...this.filter,
                locationId: this.filter.locationProgram?.locationId,
                locationProgramId: this.filter.locationProgram?.locationProgramId,
                ...this.pagination,
                sort: this.sort.join(',')
            }

            delete query.locationProgram

            this.$router.replace({query}).catch(()=>{});
        },
        filterChangeHandler() {
            this.resetPage()
            this.updateRoute()
        },
        updatePagination({page, itemsPerPage, sortBy, sortDesc}) {
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)

            this.sort = sortBy.map((fieldName, i) => `${fieldName}${sortDesc[i] ? ' desc' : ''}`)

            this.updateRoute()
        },
        resetPage() {
            this.pagination.offset = 0
        },
        resetFiltersHandler() {
            this.filter.locationProgram = {
                locationId: null,
                locationProgramId: null
            }
            this.filter.status = null
            this.filter.role = null

            this.filterChangeHandler()
        },
        performAction({user, action}) {
            switch (action) {
                case UserAction.Reactivate:
                    this.activateUser(user, StuniAction.REACTIVATE)
                    break
                case UserAction.Deactivate:
                    this.deactivateUser(user, StuniAction.DEACTIVATE)
                    break
                case UserAction.ReInvite:
                    this.reinviteUser(user, StuniAction.REINVITE)
                    break
                default:
                    console.log(action)
            }
        },
        changeUserStatus({user, action}) {
            console.log(user, action)
            let mutation = null
            let variables = {userId: user.userId}

            switch (action) {
                case UserAction.Reactivate:
                    mutation = REACTIVATE_USER
                    break
                case UserAction.Deactivate:
                    mutation = DEACTIVATE_USER
                    break
                case UserAction.ReInvite:
                    mutation = REINVIET_USER
                    break
                default:
                    mutation = null
                    console.log(action)
            }

            if (mutation) {
                this.$apollo
                    .mutate({
                        mutation,
                        variables
                    })
                    .then(async res => {
                        await this.$apollo.provider.defaultClient.resetStore()

                        this.message = 'The student status has been updated.'
                        this.type = 'success'
                    })
                    .catch(err => {
                        this.message = 'Error updating the student status'
                        this.type = 'error'
                    })
                    .finally(() => {
                        this.showMessage({duration: 5000})
                    })
            }
        },
        reInviteUserAction({user}) {
            this.$apollo
                .mutate({
                    mutation: RE_INVITE_STAFF,
                    variables: {userId: user.userId}
                })
                .then(async res => {
                    await this.$apollo.provider.defaultClient.resetStore()

                    this.message = 'The new staff have been invited successfully.'
                    this.type = 'success'
                })
                .catch(err => {
                    this.message = 'Error inviting the new staff'
                    this.type = 'error'
                })
                .finally(() => {
                    this.showMessage({duration: 5000})
                })
        },
        refetchUsers() {
            this.$apollo.queries.users.refetch()
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections
        }),
        userStatuses() {
            return this.staticChoices['user.statuses']
        },
        userRoles() {
            return this.staticChoices['user.roles']
        },
        queryFilter() {
            const searchComparator = {_ilike: `%${this.filter.search}%`}
            return {
                _and: [
                    this.filter.search
                        ? {
                              _or: [
                                  {first_name: searchComparator},
                                  {last_name: searchComparator},
                                  {phone: searchComparator},
                                  {email: searchComparator}
                              ]
                          }
                        : {},
                    this.filter.locationProgram.locationId
                        ? {
                              location_programs: {
                                  location_program: {
                                      location_id: {_eq: this.filter.locationProgram.locationId}
                                  },
                                  location_program_id: this.filter.locationProgram.locationProgramId
                                      ? {_eq: this.filter.locationProgram.locationProgramId}
                                      : {}
                              }
                          }
                        : {},
                    {status: this.filter.status ? {_eq: this.filter.status} : {}},
                    {role: this.filter.role ? {_eq: this.filter.role} : {}},
                    {account_type: {_neq: 'student'}}
                ]
            }
        },
        orderBy() {
            let orderBy = {}
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    const [fieldName, sortDir] = sortOne.split(' ')
                    orderBy[fieldName] = sortDir === 'desc' ? 'desc_nulls_last' : 'asc_nulls_first'
                })
            }
            if (!orderBy.userId) orderBy.userId = 'asc_nulls_first'

            return orderBy
        }
    },
    destroyed() {
        this.$root.$off('change-user-status')
        this.$root.$off('staff-invited')
        this.$root.$off('reinvite-user')
        this.$store.commit('MODAL_IS_VISIBLE', false)
    }
}
</script>
