<template>
    <div class="c-staff__table flex flex-col w-full">
        <v-data-table
            id="user-table"
            ref="filterTable"
            single-select
            item-key="userId"
            :headers="headers"
            :items="tableData"
            :footer-props="footerProps"
            class="a-table w-full capitalize"
            :server-items-length="total"
            data-cy="userList"
            :loading="loading"
            :options="options"
            @click:row="rowClickHandler"
            @update:options="updatePagination"
        >
            <template class="" v-slot:item.firstName="{item}">
                <div class="w-100 truncate" :title="item.firstName">{{ item.firstName }}</div>
            </template>
            <template class="" v-slot:item.lastName="{item}">
                <div class="w-100 truncate" :title="item.lastName">{{ item.lastName }}</div>
            </template>
            <template class="" v-slot:item.status="{item}">
                <div class="uppercase font-semibold text-xs" :class="getStatusClass(item.status)">
                    {{ getStatusTitle(item.status) }}
                </div>
            </template>
            <template class="" v-slot:item.role="{item}">
                <div class="w-130 truncate">
                    {{ getRoleTitle(item.role) }}
                </div>
            </template>
            <template class="" v-slot:item.locations="{item}">
                <div class="w-200 truncate">{{ getLocations(item) }}</div>
            </template>
            <template class="" v-slot:item.locationPrograms="{item}">
                <div class="w-200 truncate" :title="getLocationPrograms(item)">
                    {{ getLocationPrograms(item) }}
                </div>
            </template>
            <template class="" v-slot:item.mfaEnabled="{item}">
<!--                <fa-icon icon="spinner" spin class="text-primary-light text-lg" />-->
                <fa-icon v-if="userIdLoading === item.userId" icon="spinner" spin class="text-lg text-primary-light mt-0 ml-2 mr-2" />
                <v-checkbox
                    v-else
                    hide-details
                    :disabled="!isAdmin"
                    class="mt-0 ml-2 mr-2"
                    :ripple="false"
                    v-model="item.mfaEnabled"
                    label=""
                    @click.prevent.stop="update2Fa(item)"
                ></v-checkbox>
            </template>

            <template class="" v-slot:item.editRecord="{item}">
                <div class="text-right flex justify-end">
                    <a
                        href="#"
                        class="hover:text-primary mx-2"
                        @click.prevent="rowClickHandler(item)"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {UserHelper} from '@/components/shared/mixins/userMixin'
import { computed, ref } from '@vue/composition-api'
import { staffService } from '@/services/staff.service'
import { DISABLE_MFA, ENABLE_MFA, UPDATE_USER_MFA_ENABLED } from '@/graphql/mutations/userMutations.gql'
import { useMutation } from '@vue/apollo-composable'

export default {
    name: 'UserTable',
    mixins: [UserHelper],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        total: {
            type: Number,
            default: 5
        },
        offset: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 10
        },
        sort: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update-pagination', 'reload-users'],
    data() {
        return {
            type: '',
            message: '',
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            loaded: false
        }
    },
    mounted() {
        this.loaded = true
    },
    methods: {
        updatePagination(options) {
            if (!this.loaded) return

            this.$emit('update-pagination', options)
        },
        setPage(page) {
            this.page = page
        },
        rowClickHandler(item) {
            this.$router.push({name: 'user-details', params: {userId: item.userId}})
        },
        getLocations(item) {
            if (!item?.locations?.length) return ''

            return item.locations.map(({name}) => name).join(', ')
        },
        getLocationPrograms(item) {
            if (!item?.locationPrograms?.length) return ''

            return item.locationPrograms
                .map(({location, program}) => `${location.name} - ${program.name}`)
                .join(', ')
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        options() {
            const sortBy = []
            const sortDesc = []
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    const [fieldName, sortDir] = sortOne.split(' ')
                    sortBy.push(fieldName)
                    sortDesc.push(sortDir === 'desc')
                })
            }
            return {
                page: this.offset / this.limit + 1,
                itemsPerPage: this.limit,
                sortBy,
                sortDesc
            }
        },
        headers() {
            const tempHeaders = [
                {text: 'Status', align: 'left', value: 'status', class: 'user-status--header'},
                {
                    text: 'First Name',
                    align: 'left',
                    value: 'firstName',
                    class: 'first-name--header w-200'
                },
                {
                    text: 'Last Name',
                    align: 'left',
                    value: 'lastName',
                    class: 'last-name--header w-200'
                },
                {text: 'Role', align: 'left', value: 'role', class: ''},
                {text: 'State(s)', align: 'left', value: 'locations', sortable: false, class: ''},
                {
                    text: 'Program(s)',
                    align: 'left',
                    value: 'locationPrograms',
                    sortable: false,
                    class: ''
                }
            ]

            if (this.isAdmin) tempHeaders.push({text: '2FA enabled?', align: 'left', value: 'mfaEnabled', sortable: false, class: ''})
            tempHeaders.push({text: '', align: 'left', value: 'editRecord', fixed: true})

            return tempHeaders
        }
    },
    setup(_, { root, emit }) {
        const userIdLoading = ref('')
        const mfaEnabled = ref(false)
        const userId = ref('')
        const auth = root.$auth
        const userIdParams = computed(() => ({userId: userId.value}))
        const params = computed(() => ({mfaEnabled: mfaEnabled.value, userId: userId.value}))
        const isAdmin = computed(() => auth?.isAdmin)
        const { mutate, loading, onDone, onError } = useMutation(UPDATE_USER_MFA_ENABLED)
        // const { mutate: enableMfaMutate, loading: enableMfaLoading, onDone: enableMfaOnDone, onError: enableMfaOnError } = useMutation(ENABLE_MFA)
        // const { mutate: disableMfaMutate, loading: disableMfaLoading, onDone: disableMfaOnDone, onError: disableMfaOnError } = useMutation(DISABLE_MFA)

        const resetValues = () => {
            emit('reload-users')
            mfaEnabled.value = false
            userId.value = ''
        }
        // const onDoneFn = () => {
        //     if (loading.value) return
        // }
        const onDoneFnAndReset = () => {
            if (loading.value) return
            resetValues()
        }
        const onErrorFn = () => {
            if (loading.value) return
            resetValues()
        }
        onDone(onDoneFnAndReset)
        // enableMfaOnDone(onDoneFn)
        // disableMfaOnDone(onDoneFn)
        onError(onErrorFn)
        // enableMfaOnError(onErrorFn)
        // disableMfaOnError(onErrorFn)


        const update2Fa = async (item) => {
            userIdLoading.value = item.userId

            mfaEnabled.value = item.mfaEnabled
            userId.value = item.userId

            try {
                if (item.mfaEnabled) {
                    // await enableMfaMutate(userIdParams.value)
                    await staffService.enable2Fa(item.userId)
                } else {
                    // await disableMfaMutate(userIdParams.value)
                    await staffService.disable2Fa(item.userId)
                }
                await mutate(params.value)
            } catch (e) {
                console.log('===== error', e)
                emit('reload-users')
            } finally {
                userIdLoading.value = ''
            }

            // const promise = item.mfaEnabled ? staffService.enable2Fa : staffService.disable2Fa
            // promise(item.userId)
            //     .then((resp) => {
            //         console.log('respond from the call', resp)
            //         mutate(params.value)
            //         // now need to update the value in graphql and refresh the list
            //     })
            //     .catch((error) => console.error('==== error', error))
            //     .finally(() => {
            //         userIdLoading.value = ''
            //     })
        }

        return { userIdLoading, isAdmin, update2Fa }
    }
}
</script>
